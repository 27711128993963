import { useSessionStorage } from "@kablamo/kerosene-ui";
import { useEffect, useState } from "react";
import type { SessionStorageKey } from "../../../config/storage";
import {
  type ActiveLayersState,
  DEFAULT_ACTIVE_LAYERS,
  activeLayersSchema,
  useActiveLayers,
  type ActiveLayer,
} from "./useActiveLayers";

interface UseSessionStorageActiveLayersParams {
  key: SessionStorageKey;
}

export const useSessionStorageActiveLayers = ({
  key,
}: UseSessionStorageActiveLayersParams) => {
  const [storedActiveLayersArray, setStoredActiveLayersArray] =
    useSessionStorage<ActiveLayer[]>(
      key,
      DEFAULT_ACTIVE_LAYERS[key] as ActiveLayer[],
      (value): value is ActiveLayer[] => {
        try {
          activeLayersSchema.validateSync({ activeLayers: value });
          return true;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(
            "Invalid active layers data in session storage:",
            error,
          );
          return false;
        }
      },
    );

  const [initialState] = useState<ActiveLayersState>(() => {
    return {
      activeLayers: new Map(
        storedActiveLayersArray.map((layer) => [layer.id, layer]),
      ),
      activeLegend: null,
    };
  });

  const activeLayers = useActiveLayers({ initialState });

  const { state } = activeLayers;

  useEffect(() => {
    const serialised = Array.from(state.activeLayers.values());
    setStoredActiveLayersArray(serialised);
  }, [setStoredActiveLayersArray, state.activeLayers]);

  return activeLayers;
};
